import React from "react";

const Home = () => {
  const handleSearch = () => {
    const searchQuery = document
      .getElementById("searchInput")
      .value.toLowerCase();
    const downloadLinks = document.getElementsByClassName("download-link");

    Array.from(downloadLinks).forEach((link) => {
      const downloadName = link.textContent.toLowerCase();
      if (downloadName.includes(searchQuery)) {
        link.style.display = "block";
      } else {
        link.style.display = "none";
      }
    });
  };
  return (
    <div className="home">
      <h1 className="title">OS Downloads</h1>
      <div className="filter">
        <input
          type="text"
          id="searchInput"
          placeholder="Search downloads..."
          onInput={handleSearch}
        />
      </div>
      <div className="downloads">
        <a
          href="https://archive.org/download/windows-95_202310/W95BJ.iso"
          className="download-link"
        >
          WINDOWS 95
        </a>
        <a
          href="https://archive.org/download/windows-xp_202309/windows%20xp.iso"
          className="download-link"
        >
          Windows Xp
        </a>
        <a
          href="https://archive.org/download/windows-11_202106/Windows%2011.iso"
          className="download-link"
        >
          Windows 11
        </a>
        <a
          href="https://releases.ubuntu.com/22.04.3/ubuntu-22.04.3-desktop-amd64.iso?_ga=2.17975024.874970664.1697386354-1454845030.1697386354"
          className="download-link"
        >
          Ubuntu 22.04.3
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/amd64/iso-cd/debian-12.2.0-amd64-netinst.iso"
          className="download-link"
        >
          Debian 12.2.0 amd64 Netinst
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/amd64/iso-cd/debian-edu-12.2.0-amd64-netinst.iso"
          className="download-link"
        >
          Debian 12.2.0 amd64 Netinst EDU
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/amd64/iso-cd/debian-mac-12.2.0-amd64-netinst.iso"
          className="download-link"
        >
          Debian 12.2.0 amd64 Netinst MAC
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/arm64/iso-cd/debian-12.2.0-arm64-netinst.iso"
          className="download-link"
        >
          Debian 12.2.0 arm64 Netinst
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/armel/iso-cd/debian-12.2.0-armel-netinst.iso"
          className="download-link"
        >
          Debian 12.2.0 armel Netinst
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/armhf/iso-cd/debian-12.2.0-armhf-netinst.iso"
          className="download-link"
        >
          Debian 12.2.0 armhf Netinst
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/i386/iso-cd/debian-12.2.0-i386-netinst.iso"
          className="download-link"
        >
          Debian 12.2.0 i386 Netinst
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/i386/iso-cd/debian-edu-12.2.0-i386-netinst.iso"
          className="download-link"
        >
          Debian 12.2.0 i386 Netinst EDU
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/i386/iso-cd/debian-mac-12.2.0-i386-netinst.iso"
          className="download-link"
        >
          Debian 12.2.0 i386 Netinst MAC
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/mips64el/iso-cd/debian-12.2.0-mips64el-netinst.iso"
          className="download-link"
        >
          Debian 12.2.0 mips64el Netinst
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/mipsel/iso-cd/debian-12.2.0-mipsel-netinst.iso"
          className="download-link"
        >
          Debian 12.2.0 mipsel Netinst
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/ppc64el/iso-cd/debian-12.2.0-ppc64el-netinst.iso"
          className="download-link"
        >
          Debian 12.2.0 ppc64el Netinst
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/s390x/iso-cd/debian-12.2.0-s390x-netinst.iso"
          className="download-link"
        >
          Debian 12.2.0 s390x Netinst
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/amd64/iso-dvd/debian-12.2.0-amd64-DVD-1.iso"
          className="download-link"
        >
          Debian 12.2.0 amd64 Netinst DVD
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/arm64/iso-dvd/debian-12.2.0-arm64-DVD-1.iso"
          className="download-link"
        >
          Debian 12.2.0 arm64 Netinst DVD
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/armel/iso-dvd/debian-12.2.0-armel-DVD-1.iso"
          className="download-link"
        >
          Debian 12.2.0 armel Netinst DVD
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/armhf/iso-dvd/debian-12.2.0-armhf-DVD-1.iso"
          className="download-link"
        >
          Debian 12.2.0 armhf Netinst DVD
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/i386/iso-dvd/debian-12.2.0-i386-DVD-1.iso"
          className="download-link"
        >
          Debian 12.2.0 i386 Netinst DVD
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/mips64el/iso-dvd/debian-12.2.0-mips64el-DVD-1.iso"
          className="download-link"
        >
          Debian 12.2.0 mips64el Netinst DVD
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/mipsel/iso-dvd/debian-12.2.0-mipsel-DVD-1.iso"
          className="download-link"
        >
          Debian 12.2.0 mipsel Netinst DVD
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/ppc64el/iso-dvd/debian-12.2.0-ppc64el-DVD-1.iso"
          className="download-link"
        >
          Debian 12.2.0 ppc64el Netinst DVD
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/s390x/iso-dvd/debian-12.2.0-s390x-DVD-1.iso"
          className="download-link"
        >
          Debian 12.2.0 s390x Netinst DVD
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/source/iso-dvd/debian-12.2.0-source-DVD-1.iso"
          className="download-link"
        >
          Debian 12.2.0 Source DVD 1
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/source/iso-dvd/debian-12.2.0-source-DVD-2.iso"
          className="download-link"
        >
          Debian 12.2.0 Source DVD 2
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/source/iso-dvd/debian-12.2.0-source-DVD-3.iso"
          className="download-link"
        >
          Debian 12.2.0 Source DVD 3
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/source/iso-dvd/debian-12.2.0-source-DVD-4.iso"
          className="download-link"
        >
          Debian 12.2.0 Source DVD 4
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/source/iso-dvd/debian-12.2.0-source-DVD-5.iso"
          className="download-link"
        >
          Debian 12.2.0 Source DVD 5
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/source/iso-dvd/debian-12.2.0-source-DVD-6.iso"
          className="download-link"
        >
          Debian 12.2.0 Source DVD 6
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/source/iso-dvd/debian-12.2.0-source-DVD-7.iso"
          className="download-link"
        >
          Debian 12.2.0 Source DVD 7
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/source/iso-dvd/debian-12.2.0-source-DVD-8.iso"
          className="download-link"
        >
          Debian 12.2.0 Source DVD 8
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/source/iso-dvd/debian-12.2.0-source-DVD-9.iso"
          className="download-link"
        >
          Debian 12.2.0 Source DVD 9
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/source/iso-dvd/debian-12.2.0-source-DVD-10.iso"
          className="download-link"
        >
          Debian 12.2.0 Source DVD 10
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/source/iso-dvd/debian-12.2.0-source-DVD-11.iso"
          className="download-link"
        >
          Debian 12.2.0 Source DVD 11
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/source/iso-dvd/debian-12.2.0-source-DVD-12.iso"
          className="download-link"
        >
          Debian 12.2.0 Source DVD 12
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/source/iso-dvd/debian-12.2.0-source-DVD-13.iso"
          className="download-link"
        >
          Debian 12.2.0 Source DVD 13
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/source/iso-dvd/debian-12.2.0-source-DVD-14.iso"
          className="download-link"
        >
          Debian 12.2.0 Source DVD 14
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/source/iso-dvd/debian-12.2.0-source-DVD-15.iso"
          className="download-link"
        >
          Debian 12.2.0 Source DVD 15
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/source/iso-dvd/debian-12.2.0-source-DVD-16.iso"
          className="download-link"
        >
          Debian 12.2.0 Source DVD 16
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/source/iso-dvd/debian-12.2.0-source-DVD-17.iso"
          className="download-link"
        >
          Debian 12.2.0 Source DVD 17
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/source/iso-dvd/debian-12.2.0-source-DVD-18.iso"
          className="download-link"
        >
          Debian 12.2.0 Source DVD 18
        </a>
        <a
          href="https://cdimage.debian.org/debian-cd/current/source/iso-dvd/debian-12.2.0-source-DVD-19.iso"
          className="download-link"
        >
          Debian 12.2.0 Source DVD 19
        </a>
        <a
          href="https://releases.ubuntu.com/22.04.3/ubuntu-22.04.3-live-server-amd64.iso?_ga=2.212560733.874970664.1697386354-1454845030.1697386354"
          className="download-link"
        >
          Ubuntu Server 22.04.3
        </a>
        <a
          href="https://cdimages.ubuntu.com/edubuntu/releases/23.10/release/edubuntu-23.10-desktop-amd64.iso"
          className="download-link"
        >
          Edubuntu 23.10 Mantic Minotaur
        </a>
        <a
          href="https://cdimage.ubuntu.com/kubuntu/releases/23.10/release/kubuntu-23.10-desktop-amd64.iso"
          className="download-link"
        >
          Kubuntu 23.10
        </a>
        <a
          href="https://cdimage.ubuntu.com/lubuntu/releases/22.04.3/release/lubuntu-22.04.3-desktop-amd64.iso"
          className="download-link"
        >
          Lubuntu 22.04.3 LTS (Jammy Jellyfish)
        </a>
        <a
          href="https://cdimage.ubuntu.com/lubuntu/releases/23.10/release/lubuntu-23.10-desktop-amd64.iso"
          className="download-link"
        >
          Lubuntu 23.10 (Mantic Minotaur)
        </a>
        <a
          href="https://cdimage.ubuntu.com/ubuntu-budgie/releases/23.10/release/ubuntu-budgie-23.10-desktop-legacy-amd64.iso"
          className="download-link"
        >
          Ubuntu Budgie 23.10 (Legacy)
        </a>
        <a
          href="https://cdimage.ubuntu.com/ubuntu-budgie/releases/22.04.3/release/ubuntu-budgie-22.04.3-desktop-amd64.iso"
          className="download-link"
        >
          Ubuntu Budgie 22.04.3
        </a>
        <a
          href="https://sourceforge.net/projects/budgie-remix/files/budgie-raspi-23.04/lunar-budgie-preinstalled-desktop-arm64+raspi.img.xz/download"
          className="download-link"
        >
          Ubuntu Budgie 23.04 Raspberry Pi version
        </a>
        <a
          href="https://sourceforge.net/projects/budgie-remix/files/budgie-raspi-22.04/ubuntu-budgie-22.04.3-preinstalled-desktop-arm64%2Braspi.img.xz/download"
          className="download-link"
        >
          Ubuntu Budgie 23.04.3 Raspberry Pi version
        </a>
        <a
          href="https://downloads.sourceforge.net/project/ubuntu-cinnamon-remix/ubuntucinnamon-22.04.2-amd64.iso?ts=gAAAAABlLCGyDpZwOXqbdxT9HVqGj1bfJ9AKXKkgJhZjXSW0--hhBijv8pye9oVYkna7hY0L2EazFzJq166sUX4veDUBxVUd0Q%3D%3D&r=https%3A%2F%2Fsourceforge.net%2Fprojects%2Fubuntu-cinnamon-remix%2Ffiles%2Flatest%2Fdownload"
          className="download-link"
        >
          Ubuntu Cinnamon
        </a>
        <a
          href="https://cdimage.ubuntu.com/ubuntukylin/releases/22.04.1/release/ubuntukylin-22.04.3-desktop-amd64.iso"
          className="download-link"
        >
          Ubuntu Kylin 22.04.3 (Jammy Jellyfish)
        </a>
        <a
          href="https://www.ubuntukylin.com/downloads/download.php?id=91"
          className="download-link"
        >
          Ubuntu Kylin 22.04 Enhanced(R)
        </a>
        <a
          href="https://cdimage.ubuntu.com/ubuntu-mate/releases/22.04/release/ubuntu-mate-22.04.3-desktop-amd64.iso"
          className="download-link"
        >
          Ubuntu MATE 22.04.3 LTS (Jammy Jellyfish for amd64 systems)
        </a>
        <a
          href="https://cdimage.ubuntu.com/ubuntu-mate/releases/23.04/release/ubuntu-mate-23.04-desktop-amd64.iso"
          className="download-link"
        >
          Ubuntu MATE 23.04 (Lunar Lobster for amd64 systems)
        </a>
        <a
          href="https://cdimage.ubuntu.com/ubuntu-mate/releases/23.10/release/ubuntu-mate-23.10-desktop-amd64.iso"
          className="download-link"
        >
          Ubuntu MATE 23.10 (Mantic Minotaur for amd64 systems)
        </a>
        <a
          href="https://releases.ubuntu-mate.org/jammy/armhf/ubuntu-mate-22.04-desktop-armhf+raspi.img.xz"
          className="download-link"
        >
          Ubuntu MATE 22.04.3 LTS (Jammy Jellyfish for armhf systems)
        </a>
        <a
          href="https://releases.ubuntu-mate.org/jammy/arm64/ubuntu-mate-22.04-desktop-arm64+raspi.img.xz"
          className="download-link"
        >
          Ubuntu MATE 22.04.3 LTS (Jammy Jellyfish for arm64 systems)
        </a>
        <a
          href="https://cdimage.ubuntu.com/ubuntustudio/releases/22.04.3/release/ubuntustudio-22.04.3-dvd-amd64.iso"
          className="download-link"
        >
          Ubuntu Studio 22.04.3 LTS amd64
        </a>
        <a
          href="https://cdimage.ubuntu.com/ubuntustudio/releases/23.10/release/ubuntustudio-23.10-dvd-amd64.iso"
          className="download-link"
        >
          Ubuntu Studio 23.10 amd64
        </a>
        <a
          href="https://downloads.sourceforge.net/project/ubuntu-unity/22.04.1/ubuntu-unity-22.04.1.iso?ts=gAAAAABlLCPuNUB1GvuBfxbKTleLpN6Yjk0Zo7GGasfCM5DfUzUFMKEjPgRgXicJ7wzNE9nDJjcVfPybKJz4enxj7G-ckAzORw%3D%3D&r=https%3A%2F%2Fsourceforge.net%2Fprojects%2Fubuntu-unity%2Ffiles%2F22.04.1%2Fubuntu-unity-22.04.1.iso%2Fdownload"
          className="download-link"
        >
          Ubuntu Unity 22.04.1 LTS
        </a>
        <a
          href="https://cdimage.ubuntu.com/ubuntu-unity/releases/23.04/release/ubuntu-unity-23.04-desktop-amd64.iso"
          className="download-link"
        >
          Ubuntu Unity 23.04 amd64 (old stable)
        </a>
        <a
          href="https://cdimage.ubuntu.com/ubuntu-unity/releases/23.10/release/ubuntu-unity-23.10-desktop-amd64.iso"
          className="download-link"
        >
          Ubuntu Unity 23.10 amd64
        </a>
        <a
          href="https://mirror.us.leaseweb.net/ubuntu-cdimage/xubuntu/releases/22.04/release/xubuntu-22.04.3-desktop-amd64.iso"
          className="download-link"
        >
          Xubuntu 22.04.3 amd64
        </a>
        <a
          href="https://mirror.us.leaseweb.net/ubuntu-cdimage/xubuntu/releases/23.10/release/xubuntu-23.10-desktop-amd64.iso"
          className="download-link"
        >
          Xubuntu Desktop 22.04.3 amd64
        </a>
        <a
          href="https://mirror.us.leaseweb.net/ubuntu-cdimage/xubuntu/releases/23.10/release/xubuntu-23.10-minimal-amd64.iso"
          className="download-link"
        >
          Xubuntu Minimal 22.04.3 amd64
        </a>
        <a
          href="https://mirrors.advancedhosters.com/linuxmint/isos/stable/21.2/linuxmint-21.2-mate-64bit.iso"
          className="download-link"
        >
          Linuxmint 21.2 Mate 64bit
        </a>
        <a
          href="https://mirrors.advancedhosters.com/linuxmint/isos/stable/21.2/linuxmint-21.2-xfce-64bit.iso"
          className="download-link"
        >
          Linuxmint 21.2 Xfce 64bit
        </a>
        <a
          href="https://mirrors.advancedhosters.com/linuxmint/isos/stable/21.2/linuxmint-21.2-cinnamon-64bit-edge.iso"
          className="download-link"
        >
          Linuxmint 21.2 Cinnamon 64bit Edge
        </a>
        <a
          href="https://mirror.0xem.ma/arch/iso/2023.10.14/archlinux-2023.10.14-x86_64.iso"
          className="download-link"
        >
          Archlinux 2023.10.14 x86_64
        </a>
        <a
          href="https://download.manjaro.org/kde/23.0.2/manjaro-kde-23.0.2-230919-linux65.iso"
          className="download-link"
        >
          Manjaro KDE 23.0.2-230919 Linux65 Full x86_64
        </a>
        <a
          href="https://download.manjaro.org/kde/23.0.2/manjaro-kde-23.0.2-minimal-230919-linux65.iso"
          className="download-link"
        >
          Manjaro KDE 23.0.2-230919 Linux65 Minimal x86_64
        </a>
        <a
          href="https://download.manjaro.org/xfce/23.0.2/manjaro-xfce-23.0.2-230919-linux65.iso"
          className="download-link"
        >
          Manjaro XFCE 23.0.2-230919 Linux65 Full x86_64
        </a>
        <a
          href="https://download.manjaro.org/xfce/23.0.2/manjaro-xfce-23.0.2-minimal-230919-linux65.iso"
          className="download-link"
        >
          Manjaro XFCE 23.0.2-230919 Linux65 Minimal x86_64
        </a>
        <a
          href="https://download.manjaro.org/gnome/23.0.2/manjaro-gnome-23.0.2-230919-linux65.iso"
          className="download-link"
        >
          Manjaro GNOME 23.0.2-230919 Linux65 Full x86_64
        </a>
        <a
          href="https://download.manjaro.org/gnome/23.0.2/manjaro-gnome-23.0.2-minimal-230919-linux65.iso"
          className="download-link"
        >
          Manjaro GNOME 23.0.2-230919 Linux65 Minimal x86_64
        </a>
        <a
          href="https://download.manjaro.org/budgie/23.0.1/manjaro-budgie-23.0.1-230921-linux65.iso"
          className="download-link"
        >
          Manjaro BUDGIE 23.0.1-230921 Linux65 Full x86_64
        </a>
        <a
          href="https://download.manjaro.org/budgie/23.0.1/manjaro-budgie-23.0.1-minimal-230921-linux65.iso"
          className="download-link"
        >
          Manjaro BUDGIE 23.0.1-230921 Linux65 Minimal x86_64
        </a>
        <a
          href="https://download.manjaro.org/cinnamon/23.0.1/manjaro-cinnamon-23.0.1-230921-linux65.iso"
          className="download-link"
        >
          Manjaro CINNAMON 23.0.1-230921 Linux65 Full x86_64
        </a>
        <a
          href="https://download.manjaro.org/budgie/23.0.1/manjaro-budgie-23.0.1-minimal-230921-linux65.iso"
          className="download-link"
        >
          Manjaro CINNAMON 23.0.1-230921 Linux65 Minimal x86_64
        </a>
        <a
          href="https://download.manjaro.org/cinnamon/23.0.1/manjaro-cinnamon-23.0.1-230921-linux65.iso"
          className="download-link"
        >
          Manjaro CINNAMON 23.0.1-230921 Linux65 Full x86_64
        </a>
        <a
          href="https://download.manjaro.org/cinnamon/23.0.1/manjaro-cinnamon-23.0.1-minimal-230921-linux65.iso"
          className="download-link"
        >
          Manjaro CINNAMON 23.0.1-230921 Linux65 Minimal x86_64
        </a>
        <a
          href="https://download.manjaro.org/i3/23.0.1/manjaro-i3-23.0.1-230921-linux65.iso"
          className="download-link"
        >
          Manjaro i3 23.0.1-230921 Linux65 Full x86_64
        </a>
        <a
          href="https://download.manjaro.org/i3/23.0.1/manjaro-i3-23.0.1-minimal-230921-linux65.iso"
          className="download-link"
        >
          Manjaro i3 23.0.1-230921 Linux65 Minimal x86_64
        </a>
        <a
          href="https://manjaro-sway.download/download?file=manjaro-sway-22.1.2-231008-linux61.iso"
          className="download-link"
        >
          Manjaro SWAY 22.1.2-231008 Linux61 x86_64
        </a>
        <a
          href="https://download.manjaro.org/mate/23.0.1/manjaro-mate-23.0.1-230921-linux65.iso"
          className="download-link"
        >
          Manjaro MATE 23.0.1-230921 Linux65 Full x86_64
        </a>
        <a
          href="https://download.manjaro.org/mate/23.0.1/manjaro-mate-23.0.1-minimal-230921-linux65.iso"
          className="download-link"
        >
          Manjaro MATE 23.0.1-230921 Linux65 Minimal x86_64
        </a>
        <a
          href="https://download.fedoraproject.org/pub/fedora/linux/releases/38/Workstation/x86_64/iso/Fedora-Workstation-Live-x86_64-38-1.6.iso"
          className="download-link"
        >
          Fedora Workstation 38 Live ISO AMD x86_64
        </a>
        <a
          href="https://download.fedoraproject.org/pub/fedora/linux/releases/38/Workstation/aarch64/iso/Fedora-Workstation-Live-aarch64-38-1.6.iso"
          className="download-link"
        >
          Fedora Workstation 38 Live ISO ARM aarch64
        </a>
        <a
          href="https://download.fedoraproject.org/pub/fedora-secondary/releases/38/Workstation/ppc64le/iso/Fedora-Workstation-Live-ppc64le-38-1.6.iso"
          className="download-link"
        >
          Fedora Workstation 38 Live ISO Power ppc64le
        </a>
        <a
          href="https://download.fedoraproject.org/pub/fedora/linux/releases/38/Everything/x86_64/iso/Fedora-Everything-netinst-x86_64-38-1.6.iso"
          className="download-link"
        >
          Fedora Everything Netinst x86_64-38
        </a>
        <a
          href="https://download.fedoraproject.org/pub/fedora/linux/releases/38/Server/x86_64/iso/Fedora-Server-dvd-x86_64-38-1.6.iso"
          className="download-link"
        >
          Fedora Server 38 DVD AMD x86_64-38
        </a>
        <a
          href="https://download.fedoraproject.org/pub/fedora/linux/releases/38/Server/x86_64/iso/Fedora-Server-netinst-x86_64-38-1.6.iso"
          className="download-link"
        >
          Fedora Server 38 Network Install AMD x86_64-38
        </a>
        <a
          href="https://download.fedoraproject.org/pub/fedora/linux/releases/38/Server/aarch64/iso/Fedora-Server-dvd-aarch64-38-1.6.iso"
          className="download-link"
        >
          Fedora Server 38 DVD ARM aarch64
        </a>
        <a
          href="https://download.fedoraproject.org/pub/fedora/linux/releases/38/Server/aarch64/iso/Fedora-Server-netinst-aarch64-38-1.6.iso"
          className="download-link"
        >
          Fedora Server 38 Network Install ARM aarch64
        </a>
        <a
          href="https://download.fedoraproject.org/pub/fedora-secondary/releases/38/Server/ppc64le/iso/Fedora-Server-dvd-ppc64le-38-1.6.iso"
          className="download-link"
        >
          Fedora Server 38 DVD Power ppc64le
        </a>
        <a
          href="https://download.fedoraproject.org/pub/fedora-secondary/releases/38/Server/ppc64le/iso/Fedora-Server-netinst-ppc64le-38-1.6.iso"
          className="download-link"
        >
          Fedora Server 38 Network Install Power ppc64le
        </a>
        <a
          href="https://download.fedoraproject.org/pub/fedora-secondary/releases/38/Server/s390x/iso/Fedora-Server-dvd-s390x-38-1.6.iso"
          className="download-link"
        >
          Fedora Server 38 DVD IBM s390x zSystems
        </a>
        <a
          href="https://download.fedoraproject.org/pub/fedora-secondary/releases/38/Server/s390x/iso/Fedora-Server-netinst-s390x-38-1.6.iso"
          className="download-link"
        >
          Fedora Server 38 Network Install IBM s390x zSystems
        </a>
        <a href="https://www.centos.org/download/" className="download-link">
          CentOS Linux
        </a>
        <a
          href="https://download.opensuse.org/tumbleweed/iso/openSUSE-MicroOS-DVD-x86_64-Current.iso"
          className="download-link"
        >
          openSUSE MicroOS Intel or AMD 64-bit desktops, laptops, and servers
          (x86_64)
        </a>
        <a
          href="https://download.opensuse.org/ports/aarch64/tumbleweed/iso/openSUSE-MicroOS-DVD-aarch64-Current.iso"
          className="download-link"
        >
          openSUSE MicroOS UEFI Arm 64-bit servers, desktops, laptops and boards
          (aarch64)
        </a>
        <a
          href="https://download.opensuse.org/ports/ppc/tumbleweed/iso/openSUSE-MicroOS-DVD-ppc64le-Current.iso"
          className="download-link"
        >
          openSUSE MicroOS PowerPC servers, little-endian (ppc64le)
        </a>
        <a
          href="https://distfiles.gentoo.org/releases/amd64/autobuilds/20231008T170201Z/install-amd64-minimal-20231008T170201Z.iso"
          className="download-link"
        >
          Gentoo amd64 Minimal
        </a>
        <a
          href="https://distfiles.gentoo.org/releases/amd64/autobuilds/20230924T163139Z/livegui-amd64-20230924T163139Z.iso"
          className="download-link"
        >
          Gentoo amd64 LiveGUI USB Image
        </a>
        <a
          href="https://distfiles.gentoo.org/releases/arm64/autobuilds/20231008T231659Z/install-arm64-minimal-20231008T231659Z.iso"
          className="download-link"
        >
          Gentoo amd64 Minimal EFI
        </a>
        <a
          href="https://www.gentoo.org/downloads/#amd64-advanced"
          className="download-link"
        >
          Gentoo Linux Images
        </a>

        <a
          class="download-link"
          href="https://archive.org/download/macos-collection/Public%20Betas/macOS%2012%20%28Monterey%29/Monterey%2012.0%20v17.0.25.iso"
        >
          12.0 Monterey Beta
        </a>

        <a
          class="download-link"
          href="https://archive.org/download/macos-collection/Public%20Betas/macOS%2012%20%28Monterey%29/Monterey%2012.1%20v17.1.01.iso"
        >
          12.1 Monterey Beta
        </a>

        <a
          class="download-link"
          href="https://archive.org/download/macos-collection/Public%20Betas/macOS%2012%20%28Monterey%29/Monterey%2012.2%20v17.0.06.iso"
        >
          12.2 Monterey Beta
        </a>

        <a
          class="download-link"
          href="https://archive.org/download/macos-collection/Public%20Betas/macOS%2012%20%28Monterey%29/Monterey%2012.3%20v17.3.04.iso"
        >
          12.3 Monterey Beta
        </a>

        <a
          class="download-link"
          href="https://archive.org/download/macos-collection/Public%20Betas/macOS%2012%20%28Monterey%29/Monterey%2012.4%20v17.4.04.iso"
        >
          12.4 Monterey Beta
        </a>

        <a
          class="download-link"
          href="https://archive.org/download/macos-collection/Public%20Betas/macOS%2013%20%28Ventura%29/Ventura%2013.0%20v18.0.03.iso"
        >
          13.0 Ventura Beta
        </a>

        <a
          class="download-link"
          href="https://archive.org/download/macos-collection/Releases/Big%20Sur%2011.6.5%20v16.8.00.iso"
        >
          11.6.5 Big Sur
        </a>

        <a
          class="download-link"
          href="https://archive.org/download/macos-collection/Releases/Catalina%2010.15.7%20v15.7.03.iso"
        >
          10.15.7 Catalina
        </a>

        <a
          class="download-link"
          href="https://archive.org/download/macos-collection/Releases/El%20Capitan%2010.11.6%20v1.7.50.iso"
        >
          10.11.6 El Capitan
        </a>

        <a
          class="download-link"
          href="https://archive.org/download/macos-collection/Releases/High%20Sierra%2010.13.6%20v13.6.02.iso"
        >
          10.13.6 High Sierra
        </a>

        <a
          class="download-link"
          href="https://archive.org/download/macos-collection/Releases/Mojave%2010.14.6%20v14.6.06.iso"
        >
          10.14.6 Mojave
        </a>

        <a
          class="download-link"
          href="https://archive.org/download/macos-collection/Releases/Monterey%2012.6.1%20v17.6.11.iso"
        >
          12.6.1 Monterey
        </a>

        <a
          class="download-link"
          href="https://archive.org/download/macos-collection/Releases/Sierra%2010.12.6%20v12.6.03.iso"
        >
          10.12.6 Sierra (12.6.03)
        </a>

        <a
          class="download-link"
          href="https://archive.org/download/macos-collection/Releases/Sierra%2010.12.6%20v12.6.06.iso"
        >
          10.12.6 Sierra (12.6.06)
        </a>

        <a
          class="download-link"
          href="https://archive.org/download/macos-collection/Releases/Ventura%2013.0%20v18.0.02.iso"
        >
          13.0 Ventura
        </a>

        <a
          class="download-link"
          href="https://archive.org/download/macos-collection/Releases/Yosemite%2010.10.5%20v1.6.43.iso"
        >
          10.10.5 Yosemite
        </a>
      </div>
    </div>
  );
};

export default Home;
